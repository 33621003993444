import "./../home.css";
import styled from "@emotion/styled";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import NotificationAddOutlinedIcon from "@mui/icons-material/NotificationAddOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { Button } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import SportsCricketOutlinedIcon from "@mui/icons-material/SportsCricketOutlined";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { GrMultimedia } from "react-icons/gr";

import { URL } from "../../constants/userConstants";
import {
  getDisplayDate,
  hoursRemaining,
  isTommorrow,
  sameDayorNot,
} from "../../utils/dateformat";
import Bottomnav from "../navbar/bottomnavbar";
import Loader from "../loader";
import Navbar from "../navbar";

const Item = styled.div`
border-bottom:4px solid #e5e7eb;
padding: 10px 0;
cursor:pointer;
`;

const Title = styled.h3`
color:black;
`;

const TitleContainer = styled.div`
margin-left:5px;
`;

const AddButton = styled(Button)`
  background-color: var(--green);
  color: #ffffff;
  width: 160px;
  margin: 0 auto;
  &:hover {
    background-color: var(--green);
    color: #ffffff;
  }
`;


const Top = styled.div`
  display: flex;
  justify-content: space-between;
  color: #595959;
  align-items: flex-start;
  padding: 5px 15px;
  background-color: #ffffff;
`;

const Image = styled.img`
  width:100px;
  border-radius: 5px;
`;

const TopDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const ViewAll = styled(Button)`
  color: #ffffff;
  text-transform: capitalize;
  font-weight: 800;
  font-size: 18px;
`;

const Spanner = styled.div`
  width: 20px;
  height: 5px;
`;

const DateText = styled.span`
color: #737373;
font-size:0.75rem;
`;

export function Match({ item }) {
  const { user, isAuthenticated, error } = useSelector((state) => state.user);
  const [upcoming, setUpcoming] = useState([]);
  const [loading, setLoading] = useState(true);
  const [date, setDate] = useState();
  const [past, setPast] = useState([]);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const i = setInterval(() => {
      setDate(new Date());
    }, 1000);
    return () => {
      clearInterval(i);
    };
  }, []);
  useEffect(() => {
    const servertoken =
      localStorage.getItem("token") && localStorage.getItem("token");
    if (!servertoken) {
      navigate("/login");
    }
  }, []);
  const handleClick = () => {
    setOpen(true);
  };
  return (
    <Item
      onClick={() => navigate(`/news-detail/${item.newsId}`, {
        state: {
          news: item
        },
      })}
    >
      <Top>
        <Image src={`https://static.cricbuzz.com/a/img/v1/i1/c${item.image}/decisions-driven-by-agendas-angelo-mathews-hits-out-at-former-selectors.jpg?d=low&p=det`} alt="" />
        <TitleContainer>
          <Title>{item.title}</Title>
          <DateText>{getDisplayDate(item.date, "i")}</DateText>
        </TitleContainer>
      </Top>
      <p>{item.details.toString().slice(0, 80)}...</p>
    </Item>
  );
}

export default Match;
