import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCfNQSmTwxMBRyQd6LrK546WV7v4jj1cqs",
  authDomain: "my-eleven-champion.firebaseapp.com",
  projectId: "my-eleven-champion",
  storageBucket: "my-eleven-champion.appspot.com",
  messagingSenderId: "452401084526",
  appId: "1:452401084526:web:7b1285c6a7f5bb670a8e7f",
  measurementId: "G-QZMP4DTG0M",
};
export const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
export const storage = getStorage(app);
export default db;
