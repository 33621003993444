import React from "react";
import "./PrivacyPolicy.css";


const PrivacyPolicy = () => {
  return (
    <>
      <div className="privacy-policy-container">
        <h1>Mefigure11 Privacy Policy</h1>

        <p>Welcome to the world of fantasy! At www.mefigure11.com, we are committed to ensuring the privacy and security of your personal information. This Privacy Policy explains how we collect, use, disclose, and protect your information when you use our fantasy platform and related services. By accessing or using our services, you agree to the terms outlined in this Privacy Policy.</p>

        <ol>
          <li>
            <p><strong>Information We Collect</strong></p>
            <p>1.1 Account Information: When you create an account, we collect your username, email address, and password to provide you with a personalized fantasy experience.</p>
            <p>1.2 Fantasy Team Data: To enhance your gaming experience, we collect information about your fantasy team, including player selections, team name, and performance statistics.</p>
            <p>1.3 Usage Information: We gather data on your interactions with our platform, such as page views, clicks, and time spent on the platform. This helps us improve our services and provide a seamless experience.</p>
          </li>

          <li>
            <p><strong>How We Use Your Information</strong></p>
            <p>2.1 Providing Services: We use your information to operate, maintain, and improve our fantasy platform, ensuring you have the best possible gaming experience.</p>
            <p>2.2 Communication: We may send you emails, notifications, or other communications to inform you about updates, promotions, or relevant fantasy news. You can opt-out of these communications at any time.</p>
            <p>2.3 Personalization: Your data helps us tailor the fantasy experience to your preferences, providing personalized recommendations and content.</p>
          </li>

          <li>
            <p><strong>Information Sharing</strong></p>
            <p>3.1 Third-Party Service Providers: We may share your information with third-party service providers to assist in platform maintenance, data analysis, and other essential services.</p>
            <p>3.2 Legal Compliance: We may disclose your information if required by law or to protect our rights, property, or safety.</p>
          </li>

          <li>
            <p><strong>Data Security</strong></p>
            <p>We implement industry-standard security measures to protect your information from unauthorized access, disclosure, alteration, and destruction. However, no method of transmission over the internet or electronic storage is entirely secure, and we cannot guarantee absolute security.</p>
          </li>

          <li>
            <p><strong>Your Choices</strong></p>
            <p>You have the right to access, update, or delete your personal information. You can manage your preferences in your account settings or contact us for assistance.</p>
          </li>

          <li>
            <p><strong>Children's Privacy</strong></p>
            <p>Our platform is not intended for users under the age of 18. We do not knowingly collect or solicit personal information from children. If we learn that we have collected personal information from a child under 18, we will take appropriate steps to delete that information.</p>
          </li>

          <li>
            <p><strong>Changes to This Privacy Policy</strong></p>
            <p>We may update this Privacy Policy from time to time. We will notify you of any significant changes through our platform or other communication channels. Your continued use of our services after such modifications constitutes acceptance of the updated Privacy Policy.</p>
          </li>

          <li>
            <p><strong>Contact Us</strong></p>
            <p>If you have any questions, concerns, or requests regarding this Privacy Policy, please contact us at <a href="mailto:mefigure11@gmail.com">mefigure11@gmail.com</a>.</p>
          </li>
        </ol>

        <p>Thank you for being part of our fantasy world!</p>
      </div>
    </>
  );
};

export default PrivacyPolicy;
