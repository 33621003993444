import styled from "@emotion/styled";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import EmojiEventsOutlinedIcon from "@mui/icons-material/EmojiEventsOutlined";
import { Button, Grid, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import SouthIcon from "@mui/icons-material/South";
import Slider from "@mui/material/Slider";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import * as Yup from "yup";
import Typography from "@mui/material/Typography";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import axios from "axios";
import PropTypes from "prop-types";
import * as React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useAlert } from "react-alert";
import { URL } from "../../constants/userConstants";
import Navbar from "../navbar";
import Bottomnav from "../navbar/bottomnavbar";
import "./deposit.css";
import BankAccount from "./Bank";
import Upi from "./Upi";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { API } from "../../actions/userAction";

const Container = styled.div`
margin: 0 10px;
  .MuiTabs-indicator {
    background-color: var(--blue) !important;
    padding: 1px 0;
  }
  .Mui-selected {
    color: var(--black) !important;
    text-transform: capitalize;
    font-weight: 600;
  }
  .MuiTab-root {
    text-transform: capitalize;
    font-family: "Open Sans";
  }
  .Mui-selected {
    background-color: #efefef;
  }
  .css-aym9vq-MuiButtonBase-root-MuiTab-root {
    flex-direction: row;
    min-height: 38px;
    padding: 8px 5px;
  }
  .css-145v6pe-MuiButtonBase-root-MuiTabScrollButton-root {
    display: none;
  }
  .MuiTabs-indicator {
    top: 0;
  }
`;

const WithdrawContainer = styled.div`
border: 1px solid #cccccc;
margin: 15px 0;
padding:15px 15px;
border-radius:5px;
`;

const AccountContainer = styled.div`
border: 1px solid #cccccc;
border-radius:5px;
`;

const Title = styled.h1`
  font-size: 18px;
  margin: 0 0 20px;
  text-transform: capitalize;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.58);
  padding: 5px 0 20px 10px;
  margin: 0 !important;
`;

const FreeButton = styled.button`
  background-color: var(--green);
  text-transform: uppercase;
  color: #ffffff;
  padding: 10px 30px;
  border: none;
  outline: none;
  border-radius: 5px;
`;

const SliderContainer = styled.div``;
const SpotsLeft = styled.div``;

const SpotsRight = styled.div``;

const Last = styled.div`
  background-color: #f6f6f6;
  padding: 10px 10px;
  display: flex;
  align-items: center;
  color: #888;
`;

const LastJ = styled.div`
  background-color: #f6f6f6;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  font-size: 12px;
  justify-content: space-between;
  color: #888;
  h1 {
    padding: 0 0 !important;
  }
`;

const CreateTeam = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: var(--black);
  color: #ffffff;
  width: 200px;
  margin: 0 auto;
  height: 35px;
  border-radius: 5px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  cursor: pointer;
  margin-top: 10px;
`;

const Heading = styled.h3`
  box-shadow: 0 2px 5px 1px rgba(64, 60, 67, 0.16);
  margin-bottom: 20px;
  font-size: 12px;
  padding: 10px 10px;
`;

const JoinBtn = styled(Button)`
  background-color: var(--green);
  color: #ffffff;
  width: 80px;
  margin-right: 50px;
`;

const JoincontestBtn = styled(Button)`
  background-color: var(--green);
  color: #ffffff;
  width: 180px;
  margin: 0 auto !important;
  &:hover {
    background-color: var(--green);
  }
`;

const JoinButtoncontainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  position: fixed;
  bottom: 0;
  border-top: 1px solid #dddddd;
  width: 100%;
  z-index: 100000000000000000000000000000;
  background-color: #ffffff;
`;

const NoContests = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  height: 400px;
  img {
    max-width: 100%;
  }
`;

const StatusC = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  background-color: #fef4de;
  border-bottom: 1px solid;
  border-bottom-color: currentcolor;
  border-color: rgba(106, 106, 106, 0.12);
`;

const TabP = styled(TabPanel)`
  .MuiBox-root {
    padding: 0 0 !important;
  }
`;

const M = styled.div`
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: flex;
  border: 1px solid #888;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  margin-right: 5px;
`;

const C = styled.div`
  height: 15px;
  width: 15px;
  border-radius: 50%;
  border: 1px solid #888;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
`;

const F = styled.div`
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 1px solid #888;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: lowercase;
  font-size: 10px;
  margin-right: 5px;
`;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function TransactionTabs({ tabs, g, livescore }) {
  const { state } = useLocation();
  const [value, setValue] = React.useState(0);
  const alert = useAlert();
  const { user, isAuthenticated, loading, error } = useSelector(
    (state) => state.user
  );
  const { id } = useParams();
  const validationSchema = Yup.object().shape({
    amount: Yup.string().required("amount is required")
  });
  const {
    register,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const navigate = useNavigate();
  useEffect(() => {
    if (state?.tab == "withdrawal") {
      setValue(1)
    }
  }, [state]);

  useEffect(() => {
    if (state?.tab == "withdrawal") {
      setValue(1)
    }
  }, [state]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onSubmit = async (formData) => {
    console.log(JSON.stringify(formData, null, 2));
    /** @type {any}*/
    if (user?.upiId || (user?.accounNumber && user?.ifsc)) {
      API.post(`${URL}/payment/withdraw`,
        { ...formData, userId: user._id })
        .then((l) => {
          console.log("added to database", l);
          alert.success("withdraw requested successfully");
        }).catch((e) => {
          console.log(e.response)
          alert.error(e.response.data.message)
        });
    }
    else {
      alert("update account details")
    }
    //e.preventDefault();
  };

  return (
    <>
      <Navbar />
      <Container style={{ zIndex: "1" }}>
        <WithdrawContainer>
          <Title>Withdraw</Title>
          <Box sx={{ width: "100%" }}>
          </Box>
          <form onSubmit={handleSubmit(onSubmit)} className="">
            <TextField
              id="amount"
              name="amount"
              label="amount"
              variant="standard"
              fullWidth
              margin="dense"
              {...register("amount")}
              InputLabelProps={{ shrink: true }}
              error={errors.utr ? true : false}
            />
            {(user?.upiId || (user?.accountNumber && user?.ifsc)) && <Button
              variant="contained"
              type="submit"
              disableElevation
              style={{ backgroundColor: "#24B937", marginTop: "10px" }}
            >
              Withdraw
            </Button>}
          </form>

        </WithdrawContainer>
        <AccountContainer>
          <Title>transfer online</Title>
          <Box sx={{ width: "100%" }}>
            <Box>
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                aria-label="scrollable force tabs example"
              >
                <Tab
                  icon={<AccountBalanceIcon style={{ margin: "0 5px" }} />}
                  label="UPI"
                  {...a11yProps(0)}
                />

                <Tab
                  icon={<AccountBalanceIcon style={{ margin: "0 5px" }} />}
                  label={`Bank Account`}
                  {...a11yProps(1)}
                />
              </Tabs>
            </Box>

            <TabPanel value={value} index={0}>
              <Upi />
            </TabPanel>

            <TabPanel value={value} index={1}>
              <BankAccount />
            </TabPanel>
          </Box>
        </AccountContainer>
      </Container>
      <Bottomnav />
    </>
  );
}
