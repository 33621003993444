import styled from "@emotion/styled";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button, Grid } from "@mui/material";
import axios from "axios";
import PropTypes from "prop-types";
import * as React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useAlert } from "react-alert";
import { URL } from "../../constants/userConstants";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";

import { storage } from "../../firebase";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  getStorage,
} from "firebase/storage";
import { API } from "../../actions/userAction";

const Container = styled.div`
  padding: 15px 15px;
  background-color: #efefef;
  height: 200px;
  .MuiStack-root {
    overflow: unset !important;
  }
`;

const SubContainer = styled.div`
  padding: 15px 15px;
`;

const Label = styled.label`
  font-weight: 700;
  width: 130px;
  float: left;
  text-align: right;
  margin-right: 5px;
  font-size: 14px;
  font-weight: 700;
`;
const Row = styled.div``;
const Sub = styled.span`
  font-size: 14px;
`;

const Image = styled.img`
  max-width: 100%;
  margin: 5px 0;
`;

const Heading = styled.h1`
  color: #000;
  font-size: 17px;
  margin: 0 0 20px;
  margin-bottom: 20px;
  line-height: 26px;
  text-transform: capitalize;
  border-bottom: 1px solid rgba(15, 35, 39, 0.4);
  padding: 10px 0 9.5px;
`;

const Note = styled.p`
  color: #fe0000;
  font-size: 12px;
`;

const TabP = styled(TabPanel)`
  .MuiBox-root {
    padding: 0 0 !important;
  }
`;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BankAccount({ tabs, g, livescore }) {
  const { user, isAuthenticated, loading, error } = useSelector(
    (state) => state.user
  );
  const alert = useAlert();
  const navigate = useNavigate();
  const validationSchema = Yup.object().shape({
    accountNumber: Yup.string().required("amount is required"),
    ifsc: Yup.string()
      .required("Utr is required")
      .min(6, "Password must be at least 6 characters")
      .max(40, "Password must not exceed 40 characters")
  });
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (user?.accountNumber && user?.ifsc) {
      setValue("accountNumber", user?.accountNumber);
      setValue("ifsc", user?.ifsc);
    }
  }, [user])

  const onSubmit = async (formData) => {
    console.log(JSON.stringify(formData, null, 2));
    /** @type {any}*/
    API.post(`${URL}/auth/bank`,
      { ...formData, userId: user._id })
      .then((l) => {
        console.log("added to database", l);
        //alert.success("deposit data added successfully");
      });
  };
  return (
    <>
      <Container>
        <form onSubmit={handleSubmit(onSubmit)} className="withdrawForm">
          <TextField
            required
            id="accountNumber"
            name="accountNumber"
            label="Account Number"
            variant="standard"
            fullWidth
            margin="dense"
            InputLabelProps={{ shrink: true }}
            {...register("accountNumber")}
            error={errors.amount ? true : false}
          />
          <Typography variant="inherit" color="textSecondary">
            {errors.amount?.message}
          </Typography>
          <TextField
            required
            id="ifsc"
            name="ifsc"
            label="IFSC Code"
            variant="standard"
            fullWidth
            margin="dense"
            InputLabelProps={{ shrink: true }}
            {...register("ifsc")}
            error={errors.utr ? true : false}
          />
          <Typography variant="inherit" color="textSecondary">
            {errors.upiId?.message}
          </Typography>
          <Button
            variant="contained"
            type="submit"
            disableElevation
            style={{ backgroundColor: "#24B937" }}
          >
            save
          </Button>
        </form>
      </Container>
    </>
  );
}
