import React from 'react'

const Failure = () => {
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <p>Payment Failed!</p>
        </div>
    )
}

export default Failure;