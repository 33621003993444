import React from 'react'
import "./refund.css";

const refund = () => {
    return (
        <>
            <div className='refund-policy'>
                <h1>Mefigure11 Refund Policy</h1>

                <p>Welcome to www.mefigure11.com! Our Refund Policy outlines the terms and conditions regarding refunds for any transactions made on our platform. By using our services, you agree to abide by this policy.</p>

                <ol>
                    <li>
                        <p><strong>General Refund Policy</strong></p>
                        <p>1.1 No Cash Refunds: Due to the nature of fantasy sports and the instantaneous nature of our services, we do not provide cash refunds for any transactions, including but not limited to entry fees, virtual currency purchases, or other in-app transactions.</p>
                        <p>1.2 Virtual Currency: Any virtual currency or in-app purchases made on our platform are non-refundable. Virtual currency has no cash value and can only be used within the platform for its intended purposes.</p>
                    </li>

                    <li>
                        <p><strong>Contest Entry Fees</strong></p>
                        <p>2.1 Contest Cancellation: In the event that a contest is canceled or postponed, we will refund the entry fees for affected participants. The refund may be provided in the form of virtual currency or another method determined by [www.mefigure11.com].</p>
                        <p>2.2 Participant Withdrawal: Participants who voluntarily withdraw from a contest are not eligible for refunds. It is the participant's responsibility to review and confirm their team selections before entering a contest.</p>
                    </li>

                    <li>
                        <p><strong>Technical Issues</strong></p>
                        <p>3.1 Platform Downtime: In the rare event of significant technical issues or platform downtime that affect the overall gaming experience, [Your Fantasy Platform] may, at its discretion, provide compensation or refunds to affected users.</p>
                        <p>3.2 Determination of Issues: The determination of whether technical issues warrant refunds will be made by [mefigure11.com] based on the severity and impact on user experience.</p>
                    </li>

                    <li>
                        <p><strong>Contact and Disputes</strong></p>
                        <p>4.1 Contact Us: If you believe you are eligible for a refund or have questions about our Refund Policy, please contact our customer support at <a href="mailto:mefigure11@gmail.com">mefigure11@gmail.com</a>.</p>
                        <p>4.2 Dispute Resolution: In the event of a dispute regarding refunds, [www.mefigure.com] will make reasonable efforts to resolve the issue in a fair and timely manner.</p>
                    </li>

                    <li>
                        <p><strong>Changes to Refund Policy</strong></p>
                        <p>5. We reserve the right to update or modify this Refund Policy at any time. Changes will be effective immediately upon posting on our platform. It is your responsibility to review this policy periodically.</p>
                    </li>

                    <li>
                        <p><strong>Acceptance of Refund Policy</strong></p>
                        <p>6. By using www.mefigure11.com, you acknowledge that you have read, understood, and agreed to the terms of this Refund Policy.</p>
                    </li>
                </ol>

                <p>Thank you for being part of our fantasy community!</p>
            </div>
        </>
    )
}

export default refund